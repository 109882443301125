<template>
  <b-container fluid class="patient-treatment-container">
    <b-row>
      <p class="title">Solicitação de {{ patientTreatment.type }}</p>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="2">
        <p class="label-text">Tratamento para</p>
        <span class="data disease">
          {{ patientTreatment.name }}
          <GlaucoIcon
            class="icon"
            v-if="patientTreatment.user_treatment_glauco_id"
          />
        </span>
      </b-col>

      <b-col cols="3">
        <p class="label-text">Data da solicitação</p>
        <span class="data date">
          {{ moment(patientTreatment.date).format('DD/MM/YYYY') }}
        </span>
      </b-col>

      <b-col cols="3">
        <p class="label-text">Situação do tratamento</p>
        <span
          class="data"
          :class="{ active: patientTreatment.status === 'Em andamento' }"
        >
          {{ patientTreatment.status }}
        </span>
      </b-col>

      <b-col cols="2">
        <p class="label-text">Doses agendadas</p>
        <span class="data date">
          {{ patientTreatment.scheduled_doses }}/{{
            patientTreatment.doses.length
          }}
        </span>
      </b-col>

      <b-col cols="2">
        <b-button
          class="show-button"
          variant="primary"
          block
          size="lg"
          v-b-toggle="`collapse-${patientTreatment.id}`"
          @click="changeButtonText"
        >
          {{ buttonText }}
        </b-button>
      </b-col>
    </b-row>
    <b-collapse
      :id="`collapse-${patientTreatment.id}`"
      class="collapse-wrapper mt-2"
    >
      <TreatmentRequestTableDoses
        :fields="fields"
        :items="patientTreatment.doses"
        :observations="patientTreatment.observations"
        :openAppointmentModal="openAppointmentModal"
      />
    </b-collapse>
  </b-container>
</template>

<script>
export default {
  name: 'TreatmentRequest',
  props: {
    patientTreatment: {
      type: Object,
      required: true
    },
    openAppointmentModal: {
      type: Function,
      required: true
    }
  },
  components: {
    TreatmentRequestTableDoses: () => import('./TreatmentRequestTableDoses'),
    GlaucoIcon: () => import('@/components/Glauco/GlaucoIcon')
  },
  data() {
    return {
      patient: {},
      patientId: this.$route.params.patientId,
      treatments: [],
      buttonText: 'Gerenciar',
      fields: [
        {
          key: 'dose',
          label: 'Dose',
          thStyle: { width: '5%' }
        },
        {
          key: 'type_via',
          label: 'Via',
          thStyle: { width: '10%' },
          thClass: 'text-left'
        },
        {
          key: 'procedure_drug',
          label: 'Fármaco',
          thStyle: { width: '15%' },
          thClass: 'text-left'
        },
        {
          key: 'interval',
          label: 'Aplicação',
          thStyle: { width: '15%' },
          thClass: 'text-left'
        },
        {
          key: 'laterality',
          label: 'Lateralidade',
          thStyle: { width: '12%' },
          thClass: 'text-left'
        },
        {
          key: 'date',
          label: 'Data agendada',
          thStyle: { width: '15%' },
          thClass: 'text-left'
        },
        {
          key: 'status',
          label: 'Situacao',
          thStyle: { width: '15%' },
          thClass: 'text-left'
        }
      ]
    }
  },
  methods: {
    changeButtonText() {
      this.buttonText === 'Gerenciar'
        ? (this.buttonText = 'Fechar')
        : (this.buttonText = 'Gerenciar')
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-treatment-container {
  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 15px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;

  .title {
    font-family: 'Red Hat Display';
    font-size: min(1vw, 14px);
    font-weight: 500;
    color: var(--dark-blue);
  }

  .label-text {
    font-family: 'Nunito Sans';
    font-size: min(1vw, 14px);
    font-weight: 600;
    color: var(--dark-blue);
  }

  .data {
    font-family: 'Red Hat Display';
    font-size: min(2.6vw, 18px);
    font-weight: 700;

    .icon {
      width: 16px;
    }

    &.disease {
      color: var(--neutral-600);
    }

    &.date {
      color: var(--blue-500);
    }

    &.active {
      color: var(--states-success);
    }
  }

  .show-button {
    height: 100%;
  }
}
</style>
